<template>
  <div class="main main--internal">
    <div class="page-intro">
      <div class="main-intro">
        <div class="page-intro__left">
          <div class="page-header__container">
            <h1 class="page-header page-header_w500">{{ title }}</h1>
          </div>
          <article class="article">
            <div class="article__block">
              <div class="article__content article__content_w500">
                <EditorJSComponent :text="description.text"/>
              </div>
            </div>
          </article>
          <div class="page-intro__forms">
            <div class="form-group">
              <div class="form-group__header">
                <span class="form-group__header__title">У Вас есть несовершеннолетние дети от брака?</span>
              </div>
              <div class="form-group__body">
                <div class="form-group__body__radio">
                  <label class="form-group__radio">
                    <input type="radio" v-model="stepForm.have_underage_children" :value="1">
                    <RadioStatesIcon/>
                    <span class="form-group__radio__title">Да</span>
                  </label>
                  <label class="form-group__radio">
                    <input type="radio" v-model="stepForm.have_underage_children" :value="0">
                    <RadioStatesIcon/>
                    <span class="form-group__radio__title">Нет</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="form-group__header">
                <span class="form-group__header__title">Ваш супруг/а против расторжение брака?</span>
              </div>
              <div class="form-group__body">
                <div class="form-group__body__radio">
                  <label class="form-group__radio">
                    <input type="radio" v-model="stepForm.divorce_agreement" :value="1">
                    <RadioStatesIcon/>
                    <span class="form-group__radio__title">Да</span>
                  </label>
                  <label class="form-group__radio">
                    <input type="radio" v-model="stepForm.divorce_agreement" :value="0">
                    <RadioStatesIcon/>
                    <span class="form-group__radio__title">Нет</span>
                  </label>
                </div>
              </div>
            </div>
            <div v-if="stepForm.have_underage_children === 0 && stepForm.divorce_agreement === 0" class="note">
              <p class="note__title">Судбот вам не нужен</p>
              <p class="note__description">Обратитесь в ЗАГС для подачи документов на развод.</p>
            </div>
            <div v-else>
              <div v-if="stepForm.divorce_agreement || stepForm.have_underage_children" class="note-true">
                <p class="note-true__title">Судбот Вам поможет!</p>
                <p class="note-true__description">Чтобы подготовить иск о разводе, алиментах, детях и разделе имущества
                  нажмите кнопку "Начать".</p>
              </div>
              <span @click="analyticsSend">
                <router-link :to="{ name: 'divorce' }" tag="button"
                           :disabled="!(stepForm.have_underage_children || stepForm.divorce_agreement)"
                           :class="['button button--fill', { 'button--disabled':  !(stepForm.have_underage_children || stepForm.divorce_agreement) }]">
                  Начать
                </router-link>
              </span>
            </div>
          </div>
        </div>
        <div class="page-intro__right">
          <div class="main__video-image">
            <img src="https://img.youtube.com/vi/Bx_yNj9qi7k/mqdefault.jpg" alt="Расторжение брака через суд"/>
            <button type="button" class="main__video-play" @click="videoModal = true">
              <PlayIcon/>
            </button>
          </div>
          <div class="main__video-footer">
            <AlertCircle/>
            <span>Расторжение брака через суд</span>
            <span class="main__video-time">02:48</span>
          </div>
          <div class="example-claim">
            <img src="/public/img/example_claim.png" alt="">
            <p>Чек-лист для подготовки иска о разводе</p>
            <div class="flex-center" sp-show-form="205635">
              <a class="button button__blue" href="#" >Скачать</a>
            </div>
          </div>
        </div>
      </div>
      <section class="main__faq" v-if="faq && faq.length">
        <h4>Часто задаваемые вопросы</h4>
        <ul class="article__faq__list">
          <li class="article__faq__list__item" :class="{ active: activeFaq === index }" v-for="(item, index) in faq"
              :key="item.id">
            <div class="article__faq__list__item__header" @click="setActiveFaq(index)">
              <h3>{{ item.question }}</h3>
              <div>
                <PlusIcon/>
                <MinusIcon/>
              </div>
            </div>
            <p class="article__faq__list__item__text">{{ item.answer }}</p>
          </li>
        </ul>
      </section>
    </div>
    <div class="modal modal__video" v-if="videoModal">
      <div class="modal__background" @click="videoModal = false"></div>
      <div class="modal__body" @click.exact="videoModal = false">
        <a href="#" class="modal__close" @click.prevent="videoModal = false">
          <CloseIcon/>
        </a>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/Bx_yNj9qi7k" title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import PlusIcon from 'components/svg/Plus.vue'
import MinusIcon from 'components/svg/Minus.vue'
import RadioStatesIcon from 'components/svg/RadioStates.vue'
import EditorJSComponent from 'components/EditorJSComponent.vue'
import PlayIcon from 'components/svg/PlayIcon.vue'
import AlertCircle from 'components/svg/AlertCircle.vue'
import CloseIcon from 'components/svg/Close.vue'

export default {
  name: 'IntroDivorce',
  components: {
    RadioStatesIcon,
    PlusIcon,
    MinusIcon,
    EditorJSComponent,
    PlayIcon,
    AlertCircle,
    CloseIcon
  },
  async asyncData({store}) {
    await store.dispatch('GET_DIVORCE_PREVIEW_PAGE')
  },
  data() {
    return {
      activeFaq: null,
      stepForm: {
        have_underage_children: null,
        divorce_agreement: null
      },
      videoModal: false
    }
  },
  watch: {
    videoModal(bool) {
      if (bool) {
        document.body.classList.add('no-scroll')
      } else {
        document.body.classList.remove('no-scroll')
      }
    }
  },
  computed: {
    title() {
      return this.$store.state.divorce_preview_page.preview.title
    },
    description() {
      return this.$store.state.divorce_preview_page.preview
    },
    faq() {
      return this.$store.state.divorce_preview_page.preview.questions
    }
  },
  metaInfo() {
    const navigator = this.$store.state.divorce_preview_page.preview
    return this.$seo(
      'common',
      navigator.meta_title,
      navigator.meta_keywords,
      navigator.meta_description
    )
  },
  methods: {
    setActiveFaq(index) {
      if (this.activeFaq === index) {
        this.activeFaq = null
      } else {
        this.activeFaq = index
      }
    },
    analyticsSend(){
      gtag('event', 'click', {
        'event_category': 'buttons',
        'event_label': 'start_divorce'
      });
    }
  },
}
</script>
